<script setup lang="ts">
import { object, string } from 'yup'
const schema = object({
  firstname: string().required('Name is required field'),
  email: string().email().required(),
  phone: string().required(),
})
</script>

<template>
  <HubspotForm portal-id="22446072" form-guid="4817f58a-219d-49a6-808a-dbd402cc7cc8" :schema="schema">
    <template #default="{ isSubmitting }">
      <HubspotInput name="firstname" label="Name *" />
      <HubspotInput class="mt-3" name="email" label="Email *" type="email" />
      <HubspotInput
        class="mt-3"
        name="phone"
        label="Phone number *"
        type="tel"
        placeholder="+1 (000) 000-0000"
        mask="+1 (###) ###-####"
      />

      <HubspotButton class="mt-9 w-full" :disabled="isSubmitting" type="submit"> Send </HubspotButton>
    </template>
  </HubspotForm>
</template>
